import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "компоненты-forms"
    }}>{`Компоненты `}<inlineCode parentName="h1">{`<Forms>`}</inlineCode></h1>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<Form>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<SimpleForm>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<TabbedForm>`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "simpleform"
    }}><inlineCode parentName="h2">{`<SimpleForm>`}</inlineCode></h2>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`initialValue (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`handleSubmit (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(values) => void`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actions (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Component`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <p>{`Компонент `}<inlineCode parentName="p">{`<SimpleForm>`}</inlineCode>{` используется только как потомок для `}<inlineCode parentName="p">{`<Create>`}</inlineCode>{` или `}<inlineCode parentName="p">{`<Edit>`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`<Create>
    {(createProps) => (
        <SimpleForm
            {...createProps}
            initialValue={{
                title: '',
                body: '',
                date: '',
            }}
        >
            <Space direction="vertical">
                <TextInput label="Title" name="title" placeholder="Enter title" />
                <TextInput label="Body" name="body" placeholder="Enter body" />
                <DateInput name="date" />
            </Space>
        </SimpleForm>
    )}
</Create>
`}</code></pre>
    <h2 {...{
      "id": "tabbedform"
    }}><inlineCode parentName="h2">{`<TabbedForm>`}</inlineCode></h2>
    <h3 {...{
      "id": "props-1"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`initialValue (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`handleSubmit (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(values) => void`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultActiveKey (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования-1"
    }}>{`Пример использования`}</h3>
    <p>{`Компонент `}<inlineCode parentName="p">{`<TabbedForm>`}</inlineCode>{` используется только как потомок для `}<inlineCode parentName="p">{`<Create>`}</inlineCode>{` или `}<inlineCode parentName="p">{`<Edit>`}</inlineCode>{`. Компонент `}<inlineCode parentName="p">{`<TabbedForm>`}</inlineCode>{` принимает обязательного потомка `}<inlineCode parentName="p">{`<FormTab>`}</inlineCode>{`, который нужен для создание структуры табов.`}</p>
    <pre><code parentName="pre" {...{}}>{`<TabbedForm
    initialValue={{
        text: "",
        text2: "",
    }}
    {...props}
    defaultActiveKey="form"
>
    <FormTab tab="Tab 1" key="form">
        <TextInput
            name="text"
            label="Text"
        />
    </FormTab>
    <FormTab tab="Tab 2" key="content">
        <TextInput
            name="text2"
            label="Text 2"
        />
    </FormTab>
</TabbedForm>
`}</code></pre>
    <h2 {...{
      "id": "создание-собственной-формы"
    }}>{`Создание собственной формы`}</h2>
    <p>{`Для создание собственной формы нужно использовать компонент `}<inlineCode parentName="p">{`<Form>`}</inlineCode>{`, передавая в него параметры:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`handleSubmit`}</inlineCode></strong>{` - который будет являться onSubmitHandler`}</li>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`initialValue`}</inlineCode></strong>{` - объект необходимый для создания формы с помощью Formik`}</li>
    </ul>
    <p>{`В потомках можно передавать абсолютно любую структура, главное чтобы в итоге был возвращен `}<inlineCode parentName="p">{`<Input>`}</inlineCode>{` компонент.`}</p>
    <p>{`Компонент `}<inlineCode parentName="p">{`<Form>`}</inlineCode>{` возвращает форму созданную с помощью `}<inlineCode parentName="p">{`Formik`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`<Form
    handleSubmit={handleSubmit}
    initialValue={initialValue}
>
      <Space direction="vertical">
        {children}
        {actions || <SaveButton message="action.save" />}
      </Space>
    </Form>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      